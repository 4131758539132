import "./index.css";
import { ReactComponent as EngeniousLogo } from "./logo.svg";

export const Logo = () => {
  const baseUrl = process.env.REACT_APP_V2_APP_BASE_URL

  return (
    <a
      href={baseUrl}
      rel="noopener noreferrer" 
      className={`logo`}
    >
      <EngeniousLogo />
    </a>
  );
};
